<template>
  <div class="autocomplete">
    <ul v-if="showSuggestions && suggestions.length">
      <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
        {{ suggestion }}
        <div class="pull-right">
          <small>
            <span v-if="info && info[index]">{{ info[index] }}</span>
          </small>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import axios from "axios";

export default {
  name: "AutoComplete",
  props: {
    suggestionsArray: {
      type: Array,
      default: () => [],
      required: false,
    },
    apicall: {
      type: String,
      default: "/ticker/suggestions",
    },
    query: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      last_query: "",
      test: "test",
    };
  },
  emits: ["update:query"],
  setup(props, { emit }) {
    const info = ref([]);
    const suggestions = ref([]);
    const showSuggestions = ref(false);
    const selectedSuggestion = ref(false);
    var lastQuery = "";
    let debounceTimeout = null;

    const fetchSuggestions = async (query) => {
      globalController.api_call(
        props.apicall + "?query=" + encodeURIComponent(query),
        (result) => {
          console.log("AUTOCOMPLETE: Result " + lastQuery);

          if (result.query && result.query.toUpperCase() != lastQuery.toUpperCase()) {
            console.log("AUTOCOMPLETE: Old query, discarded " + lastQuery + " != " + result.query);
            return;
          }
          suggestions.value = result.suggestions;
          info.value = result.extra;
          showSuggestions.value = true;
          emit("suggestionsUpdated", result.suggestions);
        },
        (error) => {
          showSuggestions.value = false;
          console.log("AUTOCOMPLETE: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    };

    /*
    const filteredSuggestions = computed(() => {
      if (!props.query) {
        return [];
      }
      return props.suggestionsArray.filter((suggestion) =>
        suggestion.toLowerCase().includes(props.query.toLowerCase())
      );
    });
*/
    const selectSuggestion = (suggestion) => {
      showSuggestions.value = false;
      selectedSuggestion.value = suggestion;
      emit("update:query", suggestion);
      emit("update:suggestionSelected", suggestion);
    };

    watch(
      () => props.query,
      (newValue) => {
        if (selectedSuggestion.value == newValue) {
          showSuggestions.value = false;
          return;
        }

        if (debounceTimeout) clearTimeout(debounceTimeout);
        lastQuery = newValue;

        debounceTimeout = setTimeout(() => {
          fetchSuggestions(newValue);
        }, 150);
      }
    );

    return {
      showSuggestions,
      suggestions,
      info,
      selectSuggestion,
    };
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  max-height: 350px;
  min-width: 250px;
  overflow-y: auto;
  color: #101010;
}

li {
  padding: 8px;
  cursor: pointer;
}

li:hover {
  background-color: #f0f0f0;
}

.autocomplete {
  position: relative;
}

ul {
  min-width: 300px;
  position: absolute;
  width: 100%;
  background: white;
  z-index: 1000;
}

.pull-right {
  float: right;
}
</style>
