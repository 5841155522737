<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <h4 class="card-title">Search a company</h4>
        </template>
        <div>
          <form class="form-horizontal" v-on:submit.prevent="submit_select">
            <div class="row">
              <div class="col-sm-10">
                <fg-input placeholder="Start typing" v-model="query">
                  <template v-slot:helpBlock>
                    
                  </template>
                </fg-input>
                <AutoComplete apicall="/company/suggestions" :suggestions="items" v-model:query="query"
                  @update:suggestionSelected="perform_search" @suggestionsUpdated="nothing" />
              </div>
            </div>
            <!--
            <div class="row">
              <label class="col-sm-2 col-form-label">Options</label>
              <div class="col-sm-10 checkbox-radios">
                <checkbox v-model="checkboxes.first">Track stock</checkbox>
              </div>
            </div>
-->
           
          </form>
          <span class="form-text pull-right">Search for a Ticker or Company </span>
        </div>
      </vnud-card>
      <CompanyInfo v-if="ticker" v-model:symbol="ticker"> </CompanyInfo>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio } from "@/components/index";
import { AutoComplete } from "@/components";
import CompanyInfo from "@/views/dashboard/company/CompanyInfo.vue";

export default {
  components: {
    //Checkbox,
    AutoComplete,
    CompanyInfo,
  },
  watch: {
    query(newValue, oldValue) {

    },
  },
  methods: {
    perform_search() {
      console.log(" SUBMIT " + this.query);
      this.$router.push("/company/view?name=" + this.query);
    },
    loadTicker(ticker) {
      if (!ticker)
        return;

      if (this.tickers.length != 0 && !this.tickers.includes(ticker)) {
        ticker = this.tickers[0];
      }

      this.ticker = ticker;
      console.log(" TICKER " + ticker);
    },
    overwriteTickers(newTickers) {
      // Method to overwrite the tickers array
      this.tickers = newTickers;
      console.log("Tickers updated:", this.tickers);
    },
    submit_select() {
      console.log(" SUBMIT " + this.query);
      this.loadTicker(this.query);
    },
  },
  data() {
    return {
      query: "",
      tickers: [],
      ticker: false,
      selected_ticker: "",
      checkboxes: {
        custom1: false,
        custom2: true,
        first: true,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true,
      },
    };
  },
};
</script>
<style></style>
