<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <h4 class="card-title ticker">COMPANY EXPLORATION</h4>
          We get companies related to the topics that we like
        </template>

        <collapse>
          <collapse-item v-for="(category, index) in categories" :key="index" :id="index" :title="category._id">
            <div>

            </div>
          </collapse-item>
        </collapse>

      </vnud-card>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import { Collapse, CollapseItem } from "@/components";
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import Timeline from "../pages/Vnud-Timeline.vue";

export default {
  components: {
    Collapse,
    CollapseItem,
  },
  created() {
    const queryParams = this.$route.query;
    this.loadData(queryParams);
  },
  data() {
    return {
      name: "loading...",
      isLoaded: false,
      categories: [],
    };
  },
  watch: {

  },
  methods: {
    async loadData(safe_name) {
      this.isLoaded = false;
      globalController.api_call(
        "/company/categories",
        (data_result) => {
          console.log("Categories result");
          this.isLoaded = true;
          this.categories = data_result.result;

          this.categories.forEach(category => {
            if (category._id === "") {
              category._id = "No category";
            }
          });
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
};
</script>
<style></style>
