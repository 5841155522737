<template>
  <div class="row">

    <div class="col-md-12">
      <time-line v-if="news_articles.length > 0">
        <time-line-item v-for="news in news_articles " :key="news" inverted
          :badge-type="getBadgeClassType(news.sentiment_score)" :badge-icon="getBadgeNowIcon(news.sentiment_score)">

          <template v-slot:header>
            <span class="pull-right image-padding" v-html="getImage(news)">
            </span>
            <div class="pull-right" v-for="tickers in news.related_exchange_tickers " :key="tickers">
              <n-button v-if="this.$route.query.symbol != tickers" @click="gotoTicker(tickers)" type="primary"
                size="sm"><small>{{ tickers }}</small></n-button>
            </div>

            <span :class="getBadgeClass(news.sentiment_score) + ' pointer'" @click="openLink(news)">
              <span v-html="getTitle(news)">
              </span>
            </span>

          </template>

          <template v-slot:content>
            <span v-if="news.tools">
              <h5 class="title" v-html="getNoBullshit(news)">
              </h5>
            </span>

            <p v-html="markdown(news.ai_summary)">
            </p>

            <p v-if="!news.ai_summary">
              {{ news.articles[0] }}
            </p>

            <!-- LEGACY... Inteligencia Artificial instead of Artificial Intelligence, spanish brainfart -->
            <p v-if="news.ia_summary && !news.ai_summary">
              <span v-html="markdown(news.ia_summary)">
              </span>
            </p>
          </template>

          <template v-slot:footer>
            <h6>
              <i class="ti-time"></i>
              {{ timeAgo(news.creation_date) }}
            </h6>
          </template>

        </time-line-item>
      </time-line>

      <div class="pagination-controls" v-if="isPagination">
        <n-button @click="loadPreviousPage" :disabled="currentPage === 1">Back</n-button>
        <n-button @click="loadNextPage" class="pull-right" :disabled="!hasMoreData">Next</n-button>
      </div>
      <div v-else>
        <i v-if="!isLoaded" class="fa fa-2x fa-spinner fa-spin"></i>
      </div>

    </div>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem } from "@/components";

import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import { cleanData } from "jquery";
import { nextTick } from 'vue'; // Vue 3 syntax

export default {
  components: {
    TimeLine,
    TimeLineItem,
  },
  props: {
    isPagination: {
      type: Boolean,
      default: false,
    },
    symbol: String,
    apicall: String,
  },
  created() {
    this.loadData();
    if (!this.isPagination)
      window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    if (!this.isPagination)
      window.removeEventListener('scroll', this.handleScroll); // Clean up the event listener
  },

  data() {
    return {
      currentPage: 0,
      hasMoreData: true,
      pageSize: 10,    // Number of items per page
      ticker: "",
      isLoaded: false,
      news_articles: [],
    };
  },
  methods: {
    handleScroll() {
      // Only load when we are already loaded
      if (!this.isLoaded)
        return;

      const scrollY = window.scrollY || window.pageYOffset;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;

      // If the user is within 300px of the bottom of the page and more data is available, load next page    
      if (scrollY + visible >= pageHeight - 300 && this.hasMoreData) {
        this.currentPage++;
        this.loadData(); // Load the next page
      }
    },
    loadPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadData();
      }
    },

    // Logic to load the next page
    loadNextPage() {
      if (this.hasMoreData) {
        this.currentPage++;
        this.loadData();
      }
    },
    gotoTicker(ticker) {
      console.log(" TICKER " + ticker);
      this.$router.push("/company/view?symbol=" + ticker);
    },
    getSentimentScore(scores) {
      // Check if scores is a ProxyArray or regular array and has at least one element
      if (Array.isArray(scores) && scores.length > 0) {
        return scores[0];
      }
      return 0; // Default value if the array is empty or not valid
    },
    getScoreFromArray(score_array) {
      let score = 0;
      try {
        score = score_array[0];
      }
      catch (err) {
        console.log(err);
      }

      return score;
    },
    openLink(article) {
      window.open(article.link);
    },
    cleanTitle(title) {
      // Split the title into an array of words
      const words = title.split(' ');

      // If the title has more than 10 words, slice the first 10 and append "..."
      if (words.length > 15) {
        return words.slice(0, 15).join(' ') + '...';
      }

      // If the title has 10 words or less, return it as is
    return title
    },
    getImage(article) {
      try {
        let gif_url = article.tools[0].function.arguments.gif_keywords;

        return "<img width='164px' src='/api/news/gif?keywords=" + gif_url + "'  onerror='this.remove();'>"
      } catch (e) {
        console.log("");        
      }

      return "";
    },
    getTitle(article) {
      try {
        let title = article.tools[0].function.arguments.title;
        if (title.length > 0)
          return title;
      } catch (e) {
        //console.log(e);
      }

      return this.cleanTitle(article.title);
    },
    getNoBullshit(article) {
      try {
        if (article.tools[0] && article.tools[0].function.arguments.no_bullshit)
          return this.markdown(article.tools[0].function.arguments.no_bullshit);
      } catch (e) {
        //console.log(e);
        return "";
      }
    },
    getBadgeClassType(score_array) {
      if (!score_array)
        return 'success';

      let score = this.getScoreFromArray(score_array);
      if (score <= -5) {
        return 'danger';
      } else if (score > -5 && score < 0) {
        return 'warning';
      } else if (score === 0) {
        return 'info';
      } else if (score > 0 && score < 5) {
        return 'primary';
      }

      return 'info';
    },
    getBadgeClass(score_array) {
      return 'badge badge-' + this.getBadgeClassType(score_array);
    },
    getBadgeIcon(score_array) {
      if (!score_array)
        return 'business_briefcase-24';
      let score = this.getScoreFromArray(score_array);

      if (score == 0) return 'business_briefcase-24';

      if (score < -7) return 'sport_user-run';
      if (score < -5) return 'design_scissors';
      if (score < 0) return 'objects_umbrella-13';
      if (score > 1) return 'ui-2_like';
      if (score > 3) return 'sport_trophy';
      if (score > 5) return 'ui-2_favourite-28';
      if (score > 7) return 'objects_diamond';

      return 'business_briefcase-24';
    },
    getBadgeNowIcon(score_array) {
      return "now-ui-icons " + this.getBadgeIcon(score_array);
    },
    markdown(src) {
      if (!src)
        return "";

      return utils.markdown(src);
    },
    timeAgo(timestamp) {
      const now = new Date().getTime(); // Current time in milliseconds
      const timeDifference = now - timestamp * 1000; // Convert timestamp to milliseconds

      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30);
      const years = Math.floor(days / 365);

      if (seconds <= 1) return `a second ago`;

      if (seconds < 60) return `${seconds} seconds ago`;

      if (minutes == 1) return `${minutes} minute ago`;
      if (minutes < 60) return `${minutes} minutes ago`;

      if (hours == 1) return `${hours} hour ago`;
      if (hours < 24) return `${hours} hours ago`;

      if (days == 1) return `${days} day ago`;
      if (days < 7) return `${days} days ago`;

      if (weeks == 1) return `a week ago`;
      if (weeks < 4) return `${weeks} weeks ago`;

      if (months == 1) return `a month ago`;
      if (months < 12) return `${months} months ago`;

      if (months < 24) return `a year ago`;
      return `${years} years ago`;
    },

    async loadData(company_name) {
      this.isLoaded = false;
      const queryParams = this.$route.query;

      let apiCall = "";

      const currentScrollPosition = window.scrollY || window.pageYOffset;

      // Does the component specify an API call?
      if (this.apicall) {
        apiCall = this.apicall;
      } else {
        apiCall = "/news/query?status=INDEXED&order_by=-creation_date"; // &creation_date__gte=1%20day&

        let l = apiCall.length;

        if (queryParams.symbol)
          apiCall += "&related_exchange_tickers=" + encodeURIComponent(queryParams.symbol);
        else
          apiCall += "&related_exchange_tickers=" + encodeURIComponent(this.symbol);

        // We don't have anything to load
        if (apiCall.length == l)
          return;
      }

      apiCall += `&skip=${this.currentPage * this.pageSize}&limit=${this.pageSize}`;

      let page = this.currentPage;

      globalController.api_call(
        apiCall,
        (result) => {
          if (result.news.length == 0) {

            if (this.isPagination) {
              this.news_articles = [];
              this.isLoaded = true;
            } else
              this.hasMoreData = false;

          } else {

            if (this.isPagination)
              this.news_articles = result.news;
            else
              this.news_articles = [...this.news_articles, ...result.news];

          }
          
          this.isLoaded = true;

          console.log("News result: Result " + page);
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  }
};
</script>
<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.image-padding {
  padding-left: 10px;
}
</style>
