<template>
  <nav class="menu-bar">
    <ul>
      <router-link to="/home" v-slot="{ href, isActive, isExactActive }" custom>
          <li
            class="nav-item"
            :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a :href="href" class="nav-link">
              <i class="now-ui-icons business_briefcase-24"></i>
              HOME
            </a>
          </li>
        </router-link>
        <router-link to="/news" v-slot="{ href, isActive, isExactActive }" custom>
          <li
            class="nav-item"
            :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a :href="href" class="nav-link">
              <i class="now-ui-icons files_single-copy-04"></i>
              NEWS
            </a>
          </li>
        </router-link>
        <router-link to="http://engineer.blue" v-slot="{ href, isActive, isExactActive }" custom>
          <li
            class="nav-item"
            :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a href="http://engineer.blue" class="nav-link">
              <i class="now-ui-icons objects_planet"></i>
              ABOUT
            </a>
          </li>
        </router-link>
        <!--
        <router-link to="/news" v-slot="{ href, isActive, isExactActive }" custom>
          <li
            class="nav-item"
            :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a :href="href" class="nav-link">
              <i class="now-ui-icons ui-1_email-85"></i>
              CONTACT
            </a>
          </li>
        </router-link>
        -->
    </ul>
  </nav>
  <hr/>
    
</template>

<script>
export default {
  name: 'vnud-menubar',
};
</script>

<style>
.menu-bar {
  
  padding: 10px;
}

.menu-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu-bar li {
  margin-right: 20px;
}

.menu-bar a {
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s;
}

.menu-bar a:hover {
  background-color: #555;
  border-radius: 5px;
}
</style>
