import { createStore } from 'vuex'

import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

// Create a new store instance.
const store = createStore({
    state() {
        console.log("STORE: Init store state ");

        let dict = {
            count: 0,
            last_error: null,
            username: localStorage.getItem("userName"),
            logged_in: false,
            user: {
                first_name: "JOHN",
                last_name: "DOE",
                my_debug_interface: false,
                my_dev_interface: false,
            },
        }

        let token = localStorage.getItem("userToken");
        if (!token) {
            console.log("STORE: There is no user to load");
            return dict;
        }

        const userData = utils.getLocalStorageUser();
        if (userData != null) {
            dict.user = userData;
            dict.logged_in = true;
        }

        return dict;
    },
    actions: {
        async updateUserKey({ state, commit }, { key, value }) {
            const stored_user = utils.getLocalStorageUser();
            if (stored_user[key] == value) {
                console.log("STORE: No change in key");
                return;
            }

            console.log("STORE: Saving");
            globalController.update_currentuser_key(key, value,
                (user) => {
                    if (user) commit('setUser', user);
                    utils.runToast(` Updated `, "now-ui-icons ui-1_bell-53", 'top-right', 'info');
                },
                (error) => {
                    commit('setError', error);
                    console.log("STORE: Error loading user");
                    utils.runToast(` ${error} `, "now-ui-icons ui-1_bell-53", 'bottom-right', 'danger');
                }
            );
        },
        async updateUser({ commit }, user) {
        },
        async fetchUser({ commit }) {
            let token = localStorage.getItem("userToken");
            if (!token) {
                console.log("There is no user to load");
                commit('setLogout');
                return;
            }

            globalController.get_currentuser(
                (user) => {
                    console.log("STORE: User fetched");
                    commit('setUser', user);
                },
                (error) => {
                    commit('setError', error);
                    console.log("STORE: Error loading user");
                    utils.runToast(` ${error} `, "now-ui-icons ui-1_bell-53", 'bottom-right', 'danger');
                },
                true // Invalidate user and load again
            )
        },
    },
    mutations: {
        init(state) {
            console.log("STORE: Init");
        },
        setError(state, lastError) {
            state.last_error = lastError;
        },
        setLogout(state) {
            console.log(" STORE LOGOUT ");
            state.logged_in = false;
            state.user = { // Reset user data
                first_name: "JOHN",
                last_name: "DOE",
                my_debug_interface: false,
                my_dev_interface: false,
            };
        },
        setUser(state, userJson) {
            console.log("STORE: Set User " + userJson.username);
            state.logged_in = true;
            state.user = userJson;
        },
        increment(state) {
            state.count++;
        }
    },
})


store.subscribe((mutation, state) => {
    // Check for the initial state
    if (mutation.type === 'init') {
        store.dispatch('fetchUser');
    }
});

// Manual commit an initial mutatio to trigger the action.
store.commit('init');

export default store;