<template>
  <div class="row">
    <NewsTimeline :key="$route.fullPath" :apicall="apicall"></NewsTimeline>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

import NewsTimeline from "@/views/dashboard/news/NewsTimeline.vue";

export default {
  components: { NewsTimeline },
  created() {
   
  },
  props: {
    apicall: String,
  },
  setLoading() { },
  data() {
    return {
      isLoaded: false,
    };
  },
  watch: {
    "$route.query": {
      handler(newQuery, oldQuery) {
        
      },
      deep: true, // Allows deep watching of nested properties
      immediate: true, // If you want the handler to be called immediately
    },
  },
};
</script>
<style></style>
